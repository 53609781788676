import styled from 'styled-components';
import media from 'styled-media-query';
import Img from 'gatsby-image';
import LocalizedLink from '../components/LocalizedLink';

export const PostWrapper = styled.article`
  display: flex;
`;

export const PostImg = styled(Img)`
  width: 244px; 
`;

export const PostImgWrapper = styled.div`
  display: none;
    ${media.greaterThan('medium')`
      display: block;
    `}  
`;
export const PostImgContentWrapper = styled.div`
  background-color: var(--bg-light);
  padding: var(--space-sm);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
    ${media.greaterThan('medium')`
      display: none;
    `}  
`;


export const PostInfo = styled.div`
  margin-left: 0;
  ${media.greaterThan('medium')`
    margin-left: var(--space-lg);
  `}    
`;

export const PostDate = styled.time`
  color: var(--text-light);
  font-size: 1.4rem;
  margin-bottom: var(--space-sm);
  margin-right: var(--space-sm);
  border-right: 1px solid #ddd;
  padding-right: var(--space-sm);
  ${media.greaterThan('large')`
    font-size: 1.6rem;
  `}  
`;
export const PostAuthor = styled.span`
  color: var(--text-light);
  font-size: 1.4rem;
  margin-bottom: var(--space-sm);
  ${media.greaterThan('large')`
    font-size: 1.6rem;
  `}  
`;


export const PostDescription = styled.p`
  color: var(--text-color);
  font-weight: 600;
  padding-bottom: var(--space);
`;

export const BackLinkBold = styled(LocalizedLink)`
  display: inline-block;
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 2rem;
  ${media.greaterThan('medium')`
    margin-bottom: 3rem;
  `}    
  `;